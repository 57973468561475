import { ShiftkeyAPIEventBus } from '../ShiftKeyAPIEventBus';
import { ErrorBus } from '../../../eventBuses/ErrorBus';
import UrlBuilder from '../helpers/UrlBuilder';
import ExceptionInterpreter from '../../../util/ExceptionInterpreter';

export default class BaseResource {
  constructor() {
    this.axiosSource = null;
    this.config = {
      manualExceptionHandling: false,
    };
    this.urlBuilder = new UrlBuilder('v1');
  }
  /**
   * Resource GET request
   *
   * Query options - None
   *
   * @param route
   * @param params
   * @param config
   * @returns {Promise}
   */
  performGet(route, params, config) {
    // if we have a source then that means
    // we have a request in flight so cancel it
    if (this.axiosSource !== null) {
      this.axiosSource.cancel();
    }
    if (!config) {
      config = this.config;
    }

    // get a new cancel token
    this.axiosSource = axios.CancelToken.source();
    const token = this.axiosSource.token;

    // create the promise
    return new Promise((resolve, reject) => {
      // build the url
      const url = this.urlBuilder.resourceURL(route, { params: params });

      // make the network call
      axios.get(url, {
        cancelToken: token,
      })
        .then(response => {
          // reset the cancel token source
          this.axiosSource = null;
          resolve(response.data);
        })
        .catch(e => {
          if (!config.manualExceptionHandling) {
            // emit Unauthorized Request Event if that's the exception we're catching
            if (ExceptionInterpreter.isUnauthorizedException(e)) {
              ShiftkeyAPIEventBus.emit(ShiftkeyAPIEventBus.events.UnauthorizedRequest);
            }
            // emit on the ErrorBus unless it's a canceled request exception
            else if (!ExceptionInterpreter.isCancelledRequestException(e)) {
              ErrorBus.emitError({ exception: e });
            }
          }
          reject(e);
        });
    });
  }
  /**
   * Resource DELETE request.
   *
   * @param route
   * @param params
   * @param config
   * @return {Promise}
   */
  performDelete(route, params, config, requestBody) {
    if (!config) {
      config = this.config;
    }

    let body = null;
    if (requestBody) {
      body = {
        data:
          {
            body: requestBody,
          },
      };
    }
    // create the promise
    return new Promise((resolve, reject) => {
      // build the url
      let url = this.urlBuilder.resourceURL(route, { params: params });

      // make the network call
      axios.delete(url, body)
        .then(response => {
          // reset the cancel token source
          resolve(response.data);
        })
        .catch(e => {
          if (!config.manualExceptionHandling) {
            // emit Unauthorized Request Event if that's the exception we're catching
            if (ExceptionInterpreter.isUnauthorizedException(e)) {
              ShiftkeyAPIEventBus.emit(ShiftkeyAPIEventBus.events.UnauthorizedRequest);
            } else {
              ErrorBus.emitError({ exception: e });
            }
          }
          reject(e);
        })
    });
  }
  /**
   * Resource POST request.
   *
   * @param route
   * @param params
   * @param data
   * @param config
   * @return {Promise}
   */
  performPost(route, params, data, config) {
    if (!config) {
      config = this.config;
    }
    // create the promise
    return new Promise((resolve, reject) => {
      // build the url
      const url = this.urlBuilder.resourceURL(route, { params });

      const body = { body: data };

      // make the network call
      axios.post(url, body)
        .then((response) => {
          // reset the cancel token source
          resolve(response.data);
        })
        .catch((e) => {
          if (!config.manualExceptionHandling) {
            // emit Unauthorized Request Event if that's the exception we're catching
            if (ExceptionInterpreter.isUnauthorizedException(e)) {
              ShiftkeyAPIEventBus.emit(ShiftkeyAPIEventBus.events.UnauthorizedRequest);
            } else {
              ErrorBus.emitError({ exception: e });
            }
          }
          reject(e);
        });
    });
  }
  /**
   * Resource PUT request.
   *
   * @param route
   * @param params
   * @param data
   * @param config
   * @return {Promise}
   */
  performPut(route, params, data, config) {
    if (!config) {
      config = this.config;
    }
    // create the promise
    return new Promise((resolve, reject) => {
      // build the url
      let url = this.urlBuilder.resourceURL(route, { params });

      // make the network call
      axios.put(url, data)
        .then(response => {
          // reset the cancel token source
          resolve(response.data);
        })
        .catch(e => {
          if (!config.manualExceptionHandling) {
            // emit Unauthorized Request Event if that's the exception we're catching
            if (ExceptionInterpreter.isUnauthorizedException(e)) {
              ShiftkeyAPIEventBus.emit(ShiftkeyAPIEventBus.events.UnauthorizedRequest);
            } else {
              ErrorBus.emitError({ exception: e });
            }
          }
          reject(e)
        })
    });
  }
  cancel() {
    if (this.axiosSource !== null) {
      this.axiosSource.cancel();
    }
  }
}
