export const IN_APP_NOTIFICATION = 1;
export const BILLING_OVERVIEW = 3;
export const RATE_OVERRIDE_EXPERIMENT = 5;
export const IN_APP_CANCELLATION = 7;
export const IN_APP_CANCELLATION_EA_SAMI = 9;
export const IN_ADVANCE_PAYOUTS = 12;
export const IN_APP_CANCELLATION_2_HOUR_DEADLINE = 15;
export const FACILITY_RATINGS = 16;
export const CLIENT_APP_DASHBOARD = 19;
export const SHIFT_DISPATCH_WITH_SELECTION = 20;
export const PBJ_REPORTS = 21;

export const flags = [
  {
    id: IN_APP_NOTIFICATION,
    name: 'In-App Notification',
  },
  {
    id: BILLING_OVERVIEW,
    name: 'Billing Overview',
  },
  {
    id: RATE_OVERRIDE_EXPERIMENT,
    name: 'Rate Override Experiment',
  },
  {
    id: IN_APP_CANCELLATION,
    name: 'In-App Cancellation',
  },
  {
    id: IN_APP_CANCELLATION_EA_SAMI,
    name: 'In-App Cancellation With EA and SAMI',
  },
  {
    id: IN_ADVANCE_PAYOUTS,
    name: 'Provider In Advance Payments',
  },
  {
    id: IN_APP_CANCELLATION_2_HOUR_DEADLINE,
    name: '2 Hour In-App Cancellation Deadline Test',
  },
  {
    id: FACILITY_RATINGS,
    name: 'Facility Ratings',
  },
  {
    id: CLIENT_APP_DASHBOARD,
    name: 'Client App Dashboard',
  },
  {
    id: SHIFT_DISPATCH_WITH_SELECTION,
    name: 'Shift Dispatch With Selection',
  },
  {
    id: PBJ_REPORTS,
    name: 'PBJ Reports',
  },
];
