/* eslint max-len: 0 */
import Enum from 'enum';

export const Resources = new Enum({
  ClientScheduleStatus: 'client/schedule/status',
  ProviderFavoriteStatus: 'provider/:providerId/favorite_status',
  ShiftsRating: 'shifts_rating',

  FacilityUtilization: 'facility/:facilityId/utilization',
  FacilityUtilizationCheckLimit: 'facility/:facilityId/utilization/check_limit/:shiftId',
  FacilityUtilizationShiftDetails: 'facility/:facilityId/utilization/shift_details/:shiftId',
  FacilityUtilizationDetails: 'facility/:facilityId/utilization/:budgetId',

  FacilityUtilizationApprovalRequests: 'utilization_approval_request/:facilityId',
  FacilityUtilizationApprovalRequest: 'utilization_approval_request/:utilizationApprovalRequestId',
  FacilityUtilizationApprovalRequestStore: 'utilization_approval_request/:shiftId',
  FacilityUtilizationApprovalRequestForShift: 'utilization_approval_request/shift/:shiftId',
  FacilityUtilizationApprovalRequestDeclineShift: 'utilization_approval_request/shift/:shiftId/decline',

  FacilityDynamicRating: 'facility/:facilityId/dynamic_rating',
  FacilityDynamicRatingDetails: 'facility/:facilityId/dynamic_rating/:id',
  FacilityDynamicRatingCalendar: 'facility/:facilityId/dynamic_rating/calendar',
});
