import { PBJ_REPORTS as PBJ_FEATURE_FLAG } from '@/data/Facility/FeatureFlags';
import { userService } from '@/services/userService/UserService';

export default [
  {
    action: 'fas fa-tachometer-alt',
    title: 'Dashboard',
    url: '/dashboard',
  },
  {
    action: 'far fa-calendar-alt',
    title: 'Schedule',
    url: '/schedule',
  },
  {
    action: 'fas fa-user-clock',
    title: 'Shifts',
    items: [
      {
        title: 'Requested Shifts',
        url: '/shifts/requested_shifts',
      },
      // TODO CL-488 uncomment, remove unresolved
      // {
      //   title: 'Worklogs',
      //   url: '/shifts/worklogs',
      // },
      {
        title: 'Unresolved Shifts',
        url: '/shifts/unresolved_shifts',
      },
    ],
  },
  {
    action: 'fas fa-clipboard',
    title: 'Reports',
    items: [
      {
        title: 'Shifts Report',
        url: '/shifts/report',
      },
      {
        title: 'PPD Report',
        url: '/ppd-report',
      },
      {
        title: 'PBJ Report',
        url: '/pbj-report',
        label: 'NEW',
        enabled: () => userService.hasFacilityFeatureFlagEnabled(PBJ_FEATURE_FLAG),
      },
    ],
  },
  {
    action: 'fa fa-file-invoice',
    title: 'Billing',
    items: [
      {
        title: 'Invoices',
        url: '/billing/invoices',
      },
    ],
  },
  {
    action: 'fas fa-users',
    title: 'Teams',
    url: '/teams',
  },
  {
    action: 'fas fa-user-md',
    title: 'Professionals',
    label: 'Updated',
    url: '/users/providers',
  },
  {
    action: 'fas fa-cogs',
    title: 'Settings',
    items: [
      {
        title: 'Notifications',
        url: '/settings/notifications',
      },
    ],
  },
  {
    action: 'fas fa-hands-helping',
    title: 'Help',
    items: [
      {
        title: 'Help Center',
        url: import.meta.env.VITE_SHIFTKEY_HELP_LINK,
        blank: true,
        useRouter: false,
      },
      {
        title: 'Contact Us',
        url: '/help',
      },
    ],
  },
  {
    action: 'fas fa-cloud-download-alt',
    title: 'Downloads',
    url: '/downloads',
  },
  {
    action: 'account_circle',
    title: 'Account',
    items: [
      {
        title: 'Profile',
        url: '/account/profile',
      },
      {
        title: 'Terms of Service',
        url: '/tos-clients',
        blank: true,
        useRouter: false,
      },
      {
        title: 'Privacy Policy',
        url: '/privacy-policy-clients',
        blank: true,
        useRouter: false,
      },
      {
        title: 'Privacy Choices',
        useRouter: false,
      },
      {
        title: 'Logout',
        url: '/auth/logout',
      },
    ],
  },
];
